<template>
  <div class="product-box product-box-skeleton position-relative">
    <div class="product-img-container w-100">
      <b-skeleton-img aspect="1:1" class="rounded mx-auto"></b-skeleton-img>
    </div>
    <div class="product-info">
      <b-skeleton height="16px" width="50px" class="mx-auto"></b-skeleton>
      <b-skeleton height="13px" width="100px" class="mx-auto"></b-skeleton>
      <b-skeleton height="19px" width="100px" class="pt-1 mx-auto"></b-skeleton>
      <div class="product-badge flex-nowrap mt-1">
        <b-skeleton height="21px" width="61px"></b-skeleton>
        <b-skeleton height="21px" width="61px"></b-skeleton>
      </div>
      <b-skeleton width="100px" height="13px" class="mx-auto"></b-skeleton>
      <b-skeleton width="32px" height="13px" class="mx-auto"></b-skeleton>
    </div>
  </div>
</template>
<script>

export default {
  name: "ProductBoxSkeleton",
};
</script>